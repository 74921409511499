export const ROUTE_EPISODE_DETAIL = {
    1: '/aflevering/001-is-iconic-masters-de-moeite-waard-hype-5c-humans-in-modern',
    2: '/aflevering/002-aflevering-dos-studio-magic-op-de-dutch-open-series',
    3: '/aflevering/003-all-is-dustin-interview-met-magic-judge-dustin-de-leeuw',
    4: '/aflevering/004-half-unstable-half-team-unified-modern-grand-prix',
    5: '/aflevering/005-daan-pruijt-en-de-weg-naar-de-world-magic-cup',
    6: '/aflevering/006-flashback-2017-terugblik-op-het-magicjaar',
    7: '/aflevering/007-rivals-of-ixalan-eerste-indrukken-vanaf-de-prerelease',
    8: '/aflevering/008-de-modern-pro-tour-weer-terug-van-weggeweest',
    9: '/aflevering/009-jace-the-mind-sculptor-beter-dan-alles',
    10: '/aflevering/010-masters-25-doomsday-of-renewed-faith',
    11: '/aflevering/011-domweg-gelukkig-op-dominaria',
    12: '/aflevering/012-cube-101-een-introductie-tot-het-format',
    13: '/aflevering/013-battlebond-scoort-wizards-met-zijn-eerste-teamdraftset',
    14: '/aflevering/014-dos-modern-finalisten-pascal-en-dave-over-hun-toernooi',
    15: '/aflevering/015-weet-core-set-2019-te-bekoren',
    16: '/aflevering/016-in-gesprek-met-magic-illustrator-titus-lunter',
    17: '/aflevering/017-daan-pruijt-nederlands-kampioen-met-een-sai-deck',
    18: '/aflevering/018-verguld-met-guilds-of-ravnica',
    19: '/aflevering/019-suzanne-helmigh-artiest-met-penseel-en-zwaard',
    20: '/aflevering/020-ultimate-masters-box-topper-of-box-flopper',
    21: '/aflevering/021-flashback-2018-terugblik-op-het-magicjaar-deel-1',
    22: '/aflevering/022-flashback-2018-terugblik-op-het-magicjaar-deel-2',
    23: '/aflevering/023-ravnica-allegiance-is-een-guildy-pleasure',
    24: '/aflevering/024-mtg-arena-duim-omhoog-voor-digitaal-magic',
    25: '/aflevering/025-commander-101-kom-anders-multiplayer-magic-spelen',
    26: '/aflevering/026-war-of-the-spark-is-een-wirwar-aan-planeswalkers',
    27: '/aflevering/027-waarom-we-magic-the-gathering-spelen',
    28: '/aflevering/028-modern-horizons-verbreedt-bijna-alle-magicformats',
    29: '/aflevering/029-core-set-2020-chandra-in-haar-element',
    30: '/aflevering/030-we-want-morph-maar-ook-de-rest-van-commander-2019',
    31: '/aflevering/031-cubevitational-krone-op-de-cube',
    32: '/aflevering/032-er-was-eens-throne-of-eldraine',
    33: '/aflevering/033-grenzen-verleggen-in-pioneer',
    34: '/aflevering/034-masterclass-cube-draften-tips-vanaf-de-cubevitational',
    35: '/aflevering/035-flashback-2019-terugblik-op-het-magicjaar',
    36: '/aflevering/036-epische-ontsnappingen-in-theros-beyond-death',
    37: '/aflevering/037-unsanctioned-ongestraft-spelregels-oprekken',
    38: '/aflevering/038-bans-babys-en-beperkende-maatregelen',
    39: '/aflevering/038-muterende-monsters-op-elkaar-stapelen-in-ikoria',
    40: '/aflevering/040-m21-is-de-zoveelste-verrassend-leuke-core-set',
    41: '/aflevering/041-zendikar-rising-en-de-opkomst-van-dubbelzijdige-kaarten',
};
